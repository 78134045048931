<template lang="html">
  <div>
    <!--  -->
    <section class="container" v-if="content && content._embedded['wp:featuredmedia']['0']" style="height:360px;overflow:hidden">
      <div class="my-widget--slide w3-display-container">
        <img src="../../assets/logo-white.png" alt="" class="my-widget--placeholder w3-display-middle">
        <img :src="content._embedded['wp:featuredmedia']['0'].source_url" alt="" class="my-widget--slide-content lazyload">
      </div>
    </section>

    <!--  -->
    <section class="container mt-5">
      <div class="row">
        <div class="col-12 col-md-8 pr-md-5">
          <div v-if="content && content.acf">
            <h2 class="page-header mt-0">
              <strong v-if="english && content.acf.title_en">{{content.acf.title_en}}</strong>
              <strong v-if="french && content.acf.title_fr">{{content.acf.title_fr}}</strong>
            </h2>
            <!-- Content -->
            <p
              class="text-justify"
              v-html="content.acf.content_en"
              v-if="english && content.acf.content_en"
            ></p>
            <p
              class="text-justify"
              v-html="content.acf.content_fr"
              v-if="french && content.acf.content_fr"
            ></p>
          </div>
          <!-- Share -->
          <Share :link="link" />
        </div>
        <!-- Sidebar -->
        <div class="col-12 col-md-4 overflow-x-hidden">
          <Sidebar />
        </div>
      </div>
    </section>
    <!--  -->

    <!-- Learn more -->
    <section v-if="recommandations.length">
      <div class="container _flex _between mt-5 mb-3">
        <h5 class="widget--see-more">
          <span v-if="french">Voir aussi</span>
          <span v-if="english">See more</span>
        </h5>
        <div class="">
          <a @click="scrollRight" class="widget--hover-brand-color pointer"
            ><i class="fal fa-angle-left mr-4 w3-xxlarge"></i
          ></a>
          <a @click="scrollLeft" class="widget--hover-brand-color pointer"
            ><i class="fal fa-angle-right w3-xxlarge"></i
          ></a>
        </div>
      </div>
      <div class="primary--background">
        <div class="container py-4">
          <div
            class="d-flex flex-nowrap flex-row align-items-center widget--more"
            id="widget--more"
          >
            <div
              class="widget--more-box"
              v-for="item in recommandations"
              :key="item.id"
            >
              <div class="widget--more-boxing">
                <h5 class="my-0">
                  <strong v-if="french && item.acf.title_fr">{{item.acf.title_fr}}</strong>
                  <strong v-if="english && item.acf.title_en">{{item.acf.title_en}}</strong>
                </h5>
                <router-link tag="button" type="button" :to="`/about/${item.slug}`" class="btn widget--brand widget--radius-link mr-4 widget--position">
                  <i class="fal fa-angle-right mr-2" aria-hidden="true"></i>
                  <span v-if="french">Lire plus</span>
                  <span v-if="english">Read more</span>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { languageMixin } from "../../mixins/language";
import axios from "axios";

export default {
  mixins: [languageMixin],
  data: () => ({
    id: null,
    slug: null,
    currentId: null,
    content: null,
  }),
  computed: {
    ...mapGetters(["about"]),
    // content() {
    //   return this.$store.getters.getAbout(this.id)
    // },
    recommandations() {
      return this.about.filter((option) => option.id != this.content.id);
    },
    link() {
      return this.$route.fullPath;
      // return this.english ? value.url + value.data.url_en : value.url + value.data.url_fr;
    },
  },
  watch: {
    '$route' (to, from) {
      if (this.slug !== to.params.slug) {
        this.content = null;
          this.getContent();
          this.$forceUpdate();
      }
    },
  },
  methods: {
    getContent() {

      const _uri = `https://admin.cabemery.org/wp-json/wp/v2/about?slug=${this.$route.params.slug}&_embed`;
      return axios
        .get(_uri)
        .then((result) => {
          this.content = result.data[0];
          console.log("About content: ", this.content);
        })
        .catch((e) => {
          console.error(e);
        });
    },
  },
  created() {
    this.slug = this.$route.params.slug;
    this.getContent()
  },
};
</script>

<style lang="css"></style>
